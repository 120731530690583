import React from "react";
import { Link } from "react-router-dom";
import {
  Nav,
  ButtonGroup,
  DropdownButton,
  Dropdown,
  Button,
} from "react-bootstrap";
import { checkTokensStorage } from "../../context/checkTokens";

const ResultActions = ({
  isPro,
  sort,
  sortSelection,
  setSnippetShow,
  snippetshow,
  setExportShow,
  setProNotification,
  exportshow,
  isdesktop = true,
  categoryshow,
  setCategoryShow,
  rows,
  setResultRows,
}) => {
  var current_selection = "Quality";
  var loginObj = checkTokensStorage();

  switch (sort) {
    case "r":
      current_selection = "Quality";
      break;
    case "t":
      current_selection = "Relevance";
      break;
    case "y":
      current_selection = "Date";
      break;
    case "v12m":
      current_selection = "Popularity";
      break;
  }

  return isdesktop === true ? (
    <div className="results-actions">
      <div className="results-actions--left-group">
        <span>Order By</span>

        <Nav
          activeKey="r"
          onSelect={(selectedKey) => sortSelection(selectedKey)}
        >
          <DropdownButton
            as={ButtonGroup}
            id="sort-nav-dropdown"
            variant="null"
            title={current_selection}
          >
            <Dropdown.Item eventKey="r">Quality</Dropdown.Item>
            <Dropdown.Item eventKey="t">Relevance</Dropdown.Item>
            <Dropdown.Item eventKey="y">Date</Dropdown.Item>
            {/* <Dropdown.Item eventKey="v12m">Popularity</Dropdown.Item> */}
          </DropdownButton>
        </Nav>

        <div className="show-snippets-wrapper">
          <span>Show Snippets</span>
          <label className="switch">
            <input
              type="checkbox"
              checked={snippetshow}
              value="1"
              onChange={(e) => setSnippetShow(!snippetshow)}
            />
            <span className="switch--slide-switch round"></span>
          </label>
        </div>
      </div>
      {loginObj.chkInstitution.institution_name && (
        <div>
          <i className="fa fa-university"></i>{" "}
          {loginObj.chkInstitution.institution_name}
          {/* <Link to="/Logout">
            <i className="fa fa-on-off"></i> Logout?
          </Link> */}
        </div>
      )}{" "}
      <div className="results-actions--right-group">
        <span>Show Results:</span>
        <Nav
          activeKey="20"
          onSelect={(selectedKey) => setResultRows(selectedKey)}
        >
          <DropdownButton
            as={ButtonGroup}
            id="sort-nav-dropdown 2"
            variant="null"
            title={rows}
          >
            <Dropdown.Item eventKey="20">20</Dropdown.Item>
            <Dropdown.Item eventKey="50">50</Dropdown.Item>
            <Dropdown.Item eventKey="100">100</Dropdown.Item>
          </DropdownButton>
        </Nav>
        <Link to="/Key" className="type-small" title="results page key">
          Results page key
        </Link>

        <button
          type="button"
          className="btn dropdown-toggle"
          title="Export articles"
          onClick={(e) => {
            setExportShow(!exportshow);
            setProNotification(isPro ? false : true);
            e.preventDefault();
          }}
        >
          Export Articles <span className="sr-only">Toggle Dropdown</span>
        </button>
      </div>
    </div>
  ) : (
    <div className="results-actions">
      <div className="results-actions--main-options">
        <div className="filter-results-toggle">
          <Button
            className="navbar-btn js-sidebar-toggle navbar-toggler navbar-dark"
            type="button"
            title="Filter results"
            onClick={(e) => {
              setCategoryShow(!categoryshow);

              if (categoryshow) {
                document.body.classList.add("result-filters--is-visible");
              } else {
                document.body.classList.remove("result-filters--is-visible");
              }
            }}
            data-toggle
          >
            <span className="navbar-toggler-icon"></span>
            Filter Results
          </Button>
        </div>
        <div className="results-actions--order-by">
          <span>Order By</span>

          <Nav
            activeKey="r"
            onSelect={(selectedKey) => sortSelection(selectedKey)}
          >
            <DropdownButton
              as={ButtonGroup}
              id="sort-nav-dropdown"
              variant="null"
              title={current_selection}
            >
              <Dropdown.Item eventKey="r">Quality</Dropdown.Item>
              <Dropdown.Item eventKey="t">Relevance</Dropdown.Item>
              <Dropdown.Item eventKey="y">Date</Dropdown.Item>
              <Dropdown.Item eventKey="v12m">Popularity</Dropdown.Item>
            </DropdownButton>
          </Nav>
        </div>
        <div className="show-snippets-wrapper">
          <span>Show Snippets</span>
          <label className="switch">
            <input
              type="checkbox"
              checked={snippetshow}
              value="1"
              onChange={(e) => setSnippetShow(!snippetshow)}
            />
            <span className="switch--slide-switch round"></span>
          </label>
        </div>
        <Link to="/Key" className="type-small" title="Results page key">
          Results page key
        </Link>
      </div>
      <div className="results-actions--more-options">
        <button
          type="button"
          className="btn dropdown-toggle"
          title="Export articles"
          onClick={(e) => {
            setExportShow(!exportshow);
            setProNotification(isPro ? false : true);
            e.preventDefault();
          }}
        >
          Export Articles <span className="sr-only">Toggle Dropdown</span>
        </button>
      </div>
    </div>
  );
};

export default ResultActions;
